import React from 'react'
import { connect } from 'react-redux'
import { getUser, getAuth } from '../../reducers'
import { saveJewelryPrevalue } from '../../actions'
import Layout from '../../layout'
import Jewelry from '../../containers/Jewelry'
import AddressForm from '../../containers/AddressForm'
import EmailSend from '../../containers/EmailSend'
import ConfirmationBlock from '../../components/ConfirmationBlock'

class PrevalueJewelry extends React.Component {

    state = {
        page: 1,
        metal: '',
        prenda: '',
        kilataje: '925',
        peso: 0,
        image1: [],
        image2: [],
        image3: [],
        image4: [],
        delivery: 2,
        agency: 0,
        address: '',
        addressRef: '',
        departamento: '',
        municipio: '',
        phone: '',
        email: '',
        contactFrom: 0
    }

    setJewelry = (entities) => {
        this.setState(entities, () => { this.setState({ page: 4 }) })
    }

    setUpdateState = (object) => {
        this.setState(object)
    }

    setAddress = (values) => {
        this.setState(values, () => {
            this.setState({ page: 5 })
        })
    }

    setEmail = (values) => {
        this.setState(values, () => {
            this.saveForm()
        })
    }


    saveForm = () => {
        const { auth, saveJewelryPrevalue } = this.props

        const formData = new FormData()
        this.state.image1.forEach(file => {
            formData.append('image1', file.originFileObj)
        })
        this.state.image2.forEach(file => {
            formData.append('image2', file.originFileObj)
        })
        this.state.image3.forEach(file => {
            formData.append('image3', file.originFileObj)
        })
        if (typeof this.state.image4 !== 'undefined') {
            this.state.image4.forEach(file => {
                formData.append('image4', file.originFileObj)
            })
        }
        formData.append('metal', this.state.metal)
        formData.append('prenda', this.state.prenda)
        formData.append('kilataje', this.state.kilataje)
        formData.append('peso', this.state.peso)
        formData.append('delivery', this.state.delivery)
        formData.append('agency', this.state.agency)
        formData.append('address', this.state.address)
        formData.append('addressRef', this.state.addressRef)
        formData.append('departamento', this.state.departamento)
        formData.append('municipio', this.state.municipio)
        formData.append('contactFrom', this.state.contactFrom)
        formData.append('nCodPers', auth.nCodPers)
        formData.append('name', auth.cNombres + ' ' + auth.cApePat + ' ' + auth.cApeMat)
        formData.append('phone', this.state.phone === '' ? auth.cMovil : this.state.phone)
        formData.append('email', this.state.email === '' || this.state.email === undefined ? auth.cMail : this.state.email)
        saveJewelryPrevalue(formData)
        this.setState({ page: 6 })
    }

    cancelAddress = () => this.setState({ page: 1, address: '', phone: '', addressRef: '', departamento: '', municipio: '', kilataje: 925 })
    cancelEmail = () => this.setState({ page: 2 })


    render() {
        const { page } = this.state
        switch (page) {
            case 4:
                return <Layout type={"frontpage"} >
                    <AddressForm cancelAddress={this.cancelAddress} setAddress={this.setAddress} />
                </Layout>
            case 5:
                return <Layout type={"frontpage"} >
                    <EmailSend cancelEmail={this.cancelEmail} setEmail={this.setEmail} />
                </Layout>
            case 6:
                return <Layout type={"frontpage"} >
                    <ConfirmationBlock
                        message={`Estimado cliente: monto podrá variar, de acuerdo a valúo físico de su artículo, un ejecutivo se comunicará con usted para programar cita de su empeño a domicilio, sujeto a zonas de cobertura. 
                        Credimas donde paga menos y le damos más.`}
                    />

                </Layout>
            default:
                return <Layout type={"frontpage"} >
                    <Jewelry setJewelry={this.setJewelry} setUpdateState={this.setUpdateState} />
                </Layout>
        }
    }
}

const mapStateToProps = (state) => ({
    auth: getUser(state, getAuth(state))
})

const mapDispatchToProps = { saveJewelryPrevalue }

export default connect(mapStateToProps, mapDispatchToProps)(PrevalueJewelry)