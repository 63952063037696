import React from 'react'
import {
    Card, Select, Form, Button, Upload, Typography, Input
} from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import background13 from '../multimedia/background13.svg'
import styles from '../styles'

const normFile = (e) => {
    if (Array.isArray(e)) {
        return e;
    }
    return e && e.fileList;
}

class Car extends React.Component {

    state = {
        m1: false,
        m2: false,
        m3: false,
        m4: false,
        m5: false
    }

    handleSubmit = (values) => {
        const { setCar } = this.props
        setCar(values)
    }

    render() {
        const { setUpdateState } = this.props
        return <Card
            style={styles.generalCard}
            bordered={false}
            cover={<img src={background13} alt={"Fondo"} />}
        >
            <Typography.Title align={"center"}>Pre-valuós</Typography.Title>
            <Form
                name={"Car"}
                onFinish={this.handleSubmit}
                layout={"vertical"}
            >
                <Form.Item label={"Tarjeta circulación"} name={"tarjeta"} rules={[{ required: true, message: 'Este campo es requerido' }]}>
                    <Input />
                </Form.Item>
                <Form.Item label={"Marca"} name={"marca"} rules={[{ required: true, message: 'Este campo es requerido' }]}>
                    <Input />
                </Form.Item>
                <Form.Item label={"Año"} name={"anno"} rules={[{ required: true, message: 'Este campo es requerido' }]}>
                    <Select>
                        <Select.Option value={"2005"} key={1} >2005</Select.Option>
                        <Select.Option value={"2006"} key={2} >2006</Select.Option>
                        <Select.Option value={"2007"} key={3} >2007</Select.Option>
                        <Select.Option value={"2008"} key={4} >2008</Select.Option>
                        <Select.Option value={"2009"} key={5} >2009</Select.Option>
                        <Select.Option value={"2010"} key={6} >2010</Select.Option>
                        <Select.Option value={"2011"} key={7} >2011</Select.Option>
                        <Select.Option value={"2012"} key={8} >2012</Select.Option>
                        <Select.Option value={"2013"} key={9} >2013</Select.Option>
                        <Select.Option value={"2014"} key={10} >2014</Select.Option>
                        <Select.Option value={"2015"} key={11} >2015</Select.Option>
                        <Select.Option value={"2016"} key={12} >2016</Select.Option>
                        <Select.Option value={"2017"} key={13} >2017</Select.Option>
                        <Select.Option value={"2018"} key={14} >2018</Select.Option>
                        <Select.Option value={"2019"} key={15} >2019</Select.Option>
                        <Select.Option value={"2020"} key={16} >2020</Select.Option>
                        <Select.Option value={"2021"} key={17} >2021</Select.Option>
                    </Select>
                </Form.Item>
                <Form.Item label={"Modelo"} name={"modelo"} rules={[{ required: true, message: 'Este campo es requerido' }]}>
                    <Input />
                </Form.Item>
                <Form.Item label={"Kilometraje"} name={"kilometraje"} rules={[{ required: true, message: 'Este campo es requerido' }]}>
                    <Input />
                </Form.Item>
                <Form.Item label={"N° de contacto"} name={"nContacto"} rules={[{ required: true, message: 'Este campo es requerido' }]}>
                    <Input type={"tel"} pattern={"[0-9]{8}"} placeholder={"########"} />
                </Form.Item>
                <Form.Item name="image1" valuePropName="fileList" getValueFromEvent={normFile} rules={[{ required: true, message: 'Este campo es requerido' }]} >
                    <Upload
                        name="image"
                        listType="picture"
                        onRemove={() => { setUpdateState({ image1: [] }) }}
                        beforeUpload={file => {
                            setUpdateState({ image1: [file] })
                            return false
                        }}
                        accept="image/png, image/jpeg"
                        maxCount={1}
                    >
                        <Button icon={<UploadOutlined />}>Subir foto delantera</Button>
                    </Upload>
                </Form.Item>
                <Form.Item name="image2" valuePropName="fileList" getValueFromEvent={normFile} rules={[{ required: true, message: 'Este campo es requerido' }]}>
                    <Upload
                        name="image"
                        listType="picture"
                        onRemove={() => { setUpdateState({ image2: [] }) }}
                        beforeUpload={file => {
                            setUpdateState({ image2: [file] })
                            return false
                        }}
                        accept="image/png, image/jpeg"
                        maxCount={1}
                    >
                        <Button icon={<UploadOutlined />}>Subir foto lateral</Button>
                    </Upload>
                </Form.Item>
                <Form.Item name="image3" valuePropName="fileList" getValueFromEvent={normFile} rules={[{ required: true, message: 'Este campo es requerido' }]} >
                    <Upload
                        name="image"
                        listType="picture"
                        onRemove={() => { setUpdateState({ image3: [] }) }}
                        beforeUpload={file => {
                            setUpdateState({ image3: [file] })
                            return false
                        }}
                        accept="image/png, image/jpeg"
                        maxCount={1}
                    >
                        <Button icon={<UploadOutlined />}>Subir foto trasera</Button>
                    </Upload>
                </Form.Item>
                <Form.Item name="image4" valuePropName="fileList" getValueFromEvent={normFile} rules={[{ required: true, message: 'Este campo es requerido' }]} >
                    <Upload
                        name="image"
                        listType="picture"
                        onRemove={() => { setUpdateState({ image4: [] }) }}
                        beforeUpload={file => {
                            setUpdateState({ image4: [file] })
                            return false
                        }}
                        accept="image/png, image/jpeg"
                        maxCount={1}
                    >
                        <Button icon={<UploadOutlined />}>Subir foto de tarjeta de circulación</Button>
                    </Upload>
                </Form.Item>
                <Form.Item name="image5" valuePropName="fileList" getValueFromEvent={normFile} rules={[{ required: true, message: 'Este campo es requerido' }]} >
                    <Upload
                        name="image"
                        listType="picture"
                        onRemove={() => { setUpdateState({ image5: [] }) }}
                        beforeUpload={file => {
                            setUpdateState({ image5: [file] })
                            return false
                        }}
                        accept="image/png, image/jpeg"
                        maxCount={1}
                    >
                        <Button icon={<UploadOutlined />}>Subir foto de ID</Button>
                    </Upload>
                </Form.Item>
                <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
                    <Button type={"primary"} htmlType="submit" block>Continuar</Button>
                </Form.Item>
            </Form>
        </Card>
    }
}


export default Car