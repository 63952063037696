import React from 'react'
import { Page, Text, View, Image, Document, StyleSheet } from '@react-pdf/renderer'
import logo from '../multimedia/logoGray.png'

const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        flexWrap: 'nowrap',
        backgroundColor: '#FFF'
    },
    section: {
        flexDirection: 'row',
        justifyContent: 'space-around',
        marginBottom: '3px'
    },
    section2: {
        flexDirection: 'row',
        justifyContent: 'space-around'
    },
    section3: {
        flexDirection: 'row',
        justifyContent: 'space-around',
        marginBottom: '9px',
        marginTop: '100px'
    },
    section4: {
        flexDirection: 'column',
        justifyContent: 'center'
    },
    title: {
        fontSize: 8,
        textAlign: 'center',
        fontWeight: 'black',
        textTransform: 'uppercase'
    },
    text: {
        fontSize: 7,
        fontWeight: 'normal',
        textTransform: 'uppercase'
    },
    textNormalCenter: {
        fontSize: 7,
        textAlign: 'center',
        fontWeight: 'normal',
        textTransform: 'uppercase'
    },
    textNormalMini: {
        fontSize: 3.5,
        textAlign: 'right',
        fontWeight: 'normal',
        textTransform: 'uppercase'
    },
    textNormalRight: {
        fontSize: 7,
        textAlign: 'right',
        fontWeight: 'normal',
        textTransform: 'uppercase'
    },
    textRed: {
        fontSize: 7,
        textAlign: 'center',
        color: 'red',
        textTransform: 'uppercase'
    },
    textMini: {
        fontSize: 4,
        textAlign: 'left',
        textTransform: 'uppercase'
    },
    boxCompany: {
        width: '35%',
        justifyContent: 'center'
    },
    boxLogo: {
        width: '25%',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    boxInfo: {
        width: '30%',
        justifyContent: 'center'
    },
    boxClient: {
        width: '95%',
        margin: 'auto',
        flexDirection: 'row'
    },
    boxClient2: {
        borderTop: 1,
        borderBottom: 1,
        width: '95%',
        margin: 'auto',
        flexDirection: 'row'
    },
    boxClient3: {
        border: 1,
        borderTop: 0,
        width: '95%',
        margin: 'auto',
        flexDirection: 'row'
    },
    textMark: {
        color: '#989898',
        fontSize: 24,
        fontWeight: 'bold',
    },
    markBox: {
        top: 275,
        left: 100,
        position: 'absolute',
        zIndex: '1000',
        transform: 'rotate(-25deg)'
    }
})

const MyDocument = ({ voucher }) => (
    <Document>
        <Page size="A4" style={styles.page}>
            <View style={styles.section3}>
                <View style={styles.boxLogo}>
                    <Image src={logo} style={{ width: '130px', height: '40px' }} />
                    <Text style={styles.text}>Factura: 01# - {voucher.nCorrelativoFactura !== null && voucher.nCorrelativoFactura.toString().padStart('9', "0")}</Text>
                </View>
                <View style={styles.boxCompany}>
                    <Text style={styles.title}>{voucher.cEmpresa}</Text>
                    <Text style={styles.title}>{voucher.cAgencia}</Text>
                    <Text style={styles.textNormalCenter}>{voucher.cDirAgen}</Text>
                    <Text style={styles.textNormalCenter}>{voucher.cDireccionAgencia}</Text>
                    <Text style={styles.textNormalCenter}>Tel.{voucher.cTelefonoAgencia}</Text>
                </View>
                <View style={styles.boxInfo}>
                    <Text style={styles.text}>R.T.N:  {voucher.cRTN}</Text>
                    <Text style={styles.text}>C.A.I: {voucher.cCai}</Text>
                    <Text style={styles.text}>Oficina principal: {voucher.cDireccionOficinaPrincipal}</Text>
                    <Text style={styles.text}>Tel: {voucher.cTelefonoOficinaPrincipal}</Text>
                    <Text style={styles.text}>Correo: {voucher.cCorreoOficinaPrincipal}</Text>
                </View>
            </View>
            <View style={styles.section}>
                <View style={styles.boxClient} >
                    <View style={{ width: '60%', justifyContent: 'flex-start', padding: '5px' }}>
                        <Text style={styles.text}>fecha: {new Date(voucher.FechaOperacion).toLocaleDateString('es')}</Text>
                        <Text style={styles.text}>CLIENTE: {voucher.cCliente}</Text>
                        <Text style={styles.text}>DIRECCION: {voucher.cDireccion}</Text>
                    </View>
                    <View style={{ width: '40%', justifyContent: 'flex-start', padding: '5px' }}>
                        <Text style={styles.text}>Vendedor: {voucher.cUsuario}</Text>
                        <Text style={styles.text}>RTN: {voucher.cRTN}</Text>
                    </View>
                </View>
            </View>

            <View style={styles.section2}>
                <View style={styles.boxClient2} >
                    <View style={{ width: '10%' }}>
                        <View style={{ borderBottom: 1 }}>
                            <Text style={styles.textNormalCenter}>Código</Text>
                        </View>
                        <View>
                            <Text style={styles.text}>&nbsp;</Text>
                        </View>
                    </View>
                    <View style={{ width: '10%' }}>
                        <View style={{ borderBottom: 1 }}>
                            <Text style={styles.textNormalCenter}>Cantidad</Text>
                        </View>
                        <View>
                            <Text style={styles.text}>&nbsp;</Text>
                        </View>
                    </View>
                    <View style={{ width: '35%' }}>
                        <View style={{ borderBottom: 1 }}>
                            <Text style={styles.textNormalCenter}>Descripción</Text>
                        </View>
                        <View>
                            <Text style={styles.text}>{voucher.TipoOperacion}: L{voucher.nIntSinIGV} D.T.: {voucher.nDiasTranscurridosAlPago}</Text>
                            <Text style={styles.text}>Factura: {voucher.cDocumento}</Text>
                            <Text style={styles.text}>N° Contrato: {voucher.cNroContrato}</Text>
                            <Text style={styles.title}>Información próximo pago:</Text>
                            <Text style={styles.text}>Fecha de pago: {voucher.dFechaVencimiento}</Text>
                            <Text style={styles.text}>Saldo: L{voucher.nNuevoSaldo}</Text>
                            <Text style={styles.text}>Comisión: L{voucher.nIGV}   D.P.:{voucher.nDiasPendiente}</Text>
                        </View>
                    </View>
                    <View style={{ width: '15%' }}>
                        <View style={{ borderBottom: 1 }}>
                            <Text style={styles.textNormalCenter}>Precio unitario</Text>
                        </View>
                        <View>
                            <Text style={styles.textNormalRight}>&nbsp;</Text>
                        </View>
                    </View>
                    <View style={{ width: '20%' }}>

                        <View style={{ borderBottom: 1 }} >
                            <Text style={styles.textNormalMini}>Descuentos y rebajas</Text>
                            <Text style={styles.textNormalMini}>autorizadas</Text>
                        </View>

                        <View>
                            <Text style={styles.textNormalRight}>L{voucher.nCapitalPagado}</Text>
                        </View>
                    </View>
                    <View style={{ borderRight: 1, width: '10%' }}>

                        <View style={{ borderBottom: 1 }}>
                            <Text style={styles.textNormalRight}>Sub-total</Text>
                        </View>
                        <View>
                            <Text style={styles.textNormalRight}> L{voucher.nInteres + voucher.nDescuento}</Text>
                        </View>
                    </View>
                </View>
            </View>
            <View style={styles.section2}>
                <View style={styles.boxClient2}>
                    <View style={{ width: '20%' }}>
                        <Text style={styles.title}>valor en letras</Text>
                    </View>
                    <View style={{ borderRight: 1, width: '50%' }}>
                        <Text style={styles.text}>{voucher.NumeroEnLetra}</Text>
                    </View>
                    <View style={{ borderRight: 1, width: '20%' }}>
                        <Text style={styles.textNormalRight}>Sub-totales L:</Text>
                        <Text style={styles.textNormalRight}>Exento L:</Text>
                        <Text style={styles.textNormalRight}>Exonerado L:</Text>
                        <Text style={styles.textNormalRight}>Gravado L:</Text>
                    </View>
                    <View style={{ borderRight: 1, width: '10%' }}>
                        <Text style={styles.textNormalRight}>{voucher.nInteres + voucher.nDescuento}</Text>
                        <Text style={styles.textNormalRight}>{voucher.nInteres + voucher.nDescuento}</Text>
                        <Text style={styles.textNormalRight}>&nbsp;</Text>
                        <Text style={styles.textNormalRight}>&nbsp;</Text>
                    </View>
                </View>
            </View>
            <View style={styles.section2}>
                <View style={styles.boxClient3}>
                    <View style={{ borderRight: 1, width: '20%' }}>
                        <Text style={styles.textNormalCenter}>N° O/C Exenta</Text>
                    </View>
                    <View style={{ borderRight: 1, width: '25%' }}>
                        <Text style={styles.textNormalCenter}>Datos del adquiriente exonerado</Text>
                    </View>
                    <View style={{ borderRight: 1, width: '25%' }}>
                        <Text style={styles.textNormalCenter}>N° de registro de la SAG</Text>
                    </View>
                    <View style={{ borderRight: 1, width: '20%' }}>
                        <Text style={styles.textNormalRight}>ISV L:</Text>
                    </View>
                    <View style={{ width: '10%' }}>
                        <Text style={styles.textNormalRight}>&nbsp;</Text>
                    </View>
                </View>
            </View>
            <View style={styles.section2}>
                <View style={styles.boxClient3}>
                    <View style={{ width: '20%' }}>
                        <Text style={styles.text}>&nbsp;</Text>
                    </View>
                    <View style={{ width: '25%' }}>
                        <Text style={styles.text}>&nbsp;</Text>
                    </View>
                    <View style={{ borderRight: 1, width: '25%' }}>
                        <Text style={styles.text}>&nbsp;</Text>
                    </View>
                    <View style={{ borderRight: 1, width: '20%' }}>
                        <Text style={styles.title}>Total a pagar L: </Text>
                    </View>
                    <View style={{ width: '10%' }}>
                        <Text style={styles.textNormalRight}>{voucher.MontoTotalOperado}</Text>
                    </View>
                </View>
            </View>
            <View style={styles.section4}>
                <Text style={styles.text}>Nota: Si el pago es efectuado con cheque y no tuviera fondos, el pago quedara anulado</Text>
            </View>
            <View style={styles.markBox}>
                <Text style={styles.textMark}>FACTURA ENTREGADA A CLIENTE</Text>
            </View>
        </Page>
    </Document>
);

export default MyDocument