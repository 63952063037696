import React from 'react'
import { connect } from 'react-redux'
import { Card, Button, Space, Image, Descriptions } from 'antd'
import { getPrevalue } from '../../reducers'
import { APIpath, changeStatus } from '../../actions'
import Layout from '../../layout'
import styles from '../../styles'
import { WhatsAppOutlined } from '@ant-design/icons'

const PrevalueJewelry = (props) => {

    const { prevalue } = props
    let images = []
    for (var i = 0; i < prevalue.img; i++) {
        images.push(i + 1)
    }
    return (
        <Layout type={"backpage"} >
            <Card style={styles.generalCard}
                title={"Prevaluo " + prevalue.id + " de " + prevalue.name}
                extra={
                    <Button
                        href={`https://wa.me/+504${prevalue.phone.replace('-', '')}?text=Estimado%20cliente%20le%20saludamos%20de%20CREDIMAS,%20su%20pre-valúo%20por%20su%20
                        ${prevalue.prenda}%20es%20de%20L${prevalue.amount},%20el%20monto%20puede%20variar%20de%20acuerdo%20a%20valúo%20físico%20en%20agencia`}
                        icon={<WhatsAppOutlined />}
                        target={"_blank"}
                        rel="noreferrer"
                    > Enviar prevaluo</Button>}
            >
                <Descriptions title={"Información del solicitante"} bordered column={1} >
                    <Descriptions.Item label={"Fecha de solicitud"} >
                        { new Date(prevalue.created_at).toLocaleDateString('es') }
                    </Descriptions.Item>
                    <Descriptions.Item label={"Tipo de cliente"}>
                        {Number(prevalue.nCodPers) !== 0 ? 'Cliente' : 'No cliente'}
                    </Descriptions.Item>
                    <Descriptions.Item label={"Nombre"}>{prevalue.name}</Descriptions.Item>
                    <Descriptions.Item label={"Teléfono"}>{prevalue.phone}</Descriptions.Item>
                    <Descriptions.Item label={"Correo"}>{prevalue.email}</Descriptions.Item>
                    {(Number(prevalue.delivery) === 2 || Number(prevalue.nCodPers) === 0) &&
                        <React.Fragment>
                            <Descriptions.Item label={"Dirección"}>{prevalue.address}</Descriptions.Item>
                            <Descriptions.Item label={"Referencia"}>{prevalue.addressRef}</Descriptions.Item>
                            <Descriptions.Item label={"Departamento"}>{prevalue.departamento}</Descriptions.Item>
                            <Descriptions.Item label={"Municipio"}>{prevalue.municipio}</Descriptions.Item>
                        </React.Fragment>
                    }
                </Descriptions>
                <Descriptions bordered title={"Datos del pre-valúo"} column={2}>
                    <Descriptions.Item label={"Tipo de metal"}> {prevalue.metal} </Descriptions.Item>
                    <Descriptions.Item label={"Tipo de prenda"}> {prevalue.prenda} </Descriptions.Item>
                    <Descriptions.Item label={"Kilataje"}> {prevalue.kilataje} </Descriptions.Item>
                    <Descriptions.Item label={"Peso"}> {prevalue.peso} </Descriptions.Item>
                    <Descriptions.Item label={"Tipo de petición"}> {Number(prevalue.delivery) === 1 ? 'Agencia' : 'A domicilio'} </Descriptions.Item>
                    <Descriptions.Item label={"Valor de pre-valúo"}> L{prevalue.amount} </Descriptions.Item>
                    {Number(prevalue.delivery) !== 2 &&
                        <Descriptions.Item label={"Agencia"}>{prevalue.agencia}</Descriptions.Item>
                    }
                </Descriptions>
                <br />
                <Space wrap={true} align={"center"}>
                    {images.map(i =>
                        <Image key={i} src={`${APIpath}/prevalue/image/${prevalue.id}/${i}`} width={200} height={200} />
                    )}
                </Space>
                {prevalue.status ? undefined : <Button onClick={() => { props.changeStatus(prevalue.id) }} >Finalizar prevalúo</Button>}
            </Card>
        </Layout>
    )
}

const mapStateToProps = (state, ownProps) => ({
    prevalue: getPrevalue(state, ownProps.match.params.id)
})


const mapDispatchToProps = { changeStatus }

export default connect(mapStateToProps, mapDispatchToProps)(PrevalueJewelry)