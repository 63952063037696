import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Table, Card, Button, Input, Space } from 'antd'
import { getAdmins } from '../../reducers'
import { fetchAdmins } from '../../actions'
import { SearchOutlined } from '@ant-design/icons'
import Layout from '../../layout'
import styles from '../../styles'

const { Column } = Table

const tableAdmin = (admins) => {
    return admins.map((admin) => {
        return {
            key: admin.id,
            name: admin.name,
            email: admin.email,
            role: Number(admin.role_id) === 1 ? 'Administrador': 'Gestión de usuarios'  
        }
    })
}

class AdminList extends React.Component {

    componentDidMount() {
        this.props.fetchAdmins()
    }

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Buscar ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ width: 188, marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Buscar
              </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reiniciar
              </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            this.setState({
                                searchText: selectedKeys[0],
                                searchedColumn: dataIndex,
                            });
                        }}
                    >
                        Filter
              </Button>
                </Space>
            </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : '',
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        render: text => (text),
        sorter: (a, b) => !!a[dataIndex] ? a[dataIndex] > b[dataIndex] ? -1 : 1 : true
    })


    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    }

    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    }

    render() {
        const { admins } = this.props
        return (
            <Layout type={"backpage"} >
                <Card style={styles.generalCard}>
                    <Table dataSource={tableAdmin(admins)} >
                        <Column title={"Nombre"}  {...this.getColumnSearchProps('name')} render={(record) => record.name} />
                        <Column title={"Correo"}  {...this.getColumnSearchProps('email')} render={(record) => record.email} />
                        <Column title={"Rol"}  {...this.getColumnSearchProps('role')} render={(record) => record.role} />
                        <Column title={"Detalles"} render={(record) =>
                            <Link to={`/admin/admin/edit/${record.key}`}>
                                <Button type={"primary"}>Editar</Button>
                            </Link>
                        } />
                    </Table>
                </Card>
            </Layout>
        )
    }
}

const mapStateToProps = (state) => ({
    admins: getAdmins(state)
})

const mapDispatchToProps = { fetchAdmins }

export default connect(mapStateToProps, mapDispatchToProps)(AdminList)