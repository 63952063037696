import React from 'react'
import { Card, Typography } from 'antd'
import Layout from '../../layout'
import styles from '../../styles'

class Faq extends React.Component {

    render() {
        return <Layout type={"frontpage"}>
            <Card
                title={<Typography.Title level={2} className={"text-center"} style={styles.whiteText}>Preguntas frecuentes</Typography.Title>}
                headStyle={styles.cover}
                style={styles.generalCard}
                bordered={false}
            >
                <Typography.Paragraph>
                    <b>1. ¿Cuánto tiempo dura un contrato?</b><br />
                    <b>R/</b> Los contratos son a 30 días prorrogables indefinidamente con sólo renovar el contrato pagando la interés. <br /><br />
                    <b>2.¿Cuáles son las formas de pago? </b> <br />
                    <b>R/</b> Cancelación: Cuando el cliente cancela Capital mas interés <br />
                        Amortización: Cliente cancela interés y puede amortizar desde un dólar en adelante<br /><br />
                    <b>3. ¿Es posible pagar en otras agencias? </b> <br />
                    <b>R/</b> Somos la única empresa en este rubro que tiene el sistema de pagos en línea y el cliente tiene la facilidad de pagar
                        en cualquiera de nuestras 36 agencias. <br /><br />
                    <b>4. ¿Es posible enviar a pagar a otra persona? </b> <br />
                    <b>R/</b> Es posible. La persona autorizada solamente deberá presentar su ID o el contrato que desee pagar. <br /><br />
                    <b>5. ¿Otra persona puede retirar las prendas? </b> <br />
                    <b>R/</b> En este caso se puede entregar únicamente a otra persona, si el tercero presenta una carta poder autenticada. <br /><br />
                    <b>6. ¿El producto/prenda es entregado el mismo día que se cancela el contrato? </b> <br />
                    <b>R/</b> Dado que para la empresa lo más importante es la seguridad del cliente, las prendas no se entregan el mismo día ya
                        que se encuentran resguardadas en una bóveda de seguridad. <br /><br />
                    <b>7. ¿Cuál es el horario de atención? </b><br />
                    <b>R/</b> Estimado, cliente le invitamos a buscar en nuestro menú / Agencias / el departamento donde reside, y podrá ver
                        dirección de agencias, croquis y n° de contacto de agencia, será un placer atenderle.
                    </Typography.Paragraph>
            </Card>
        </Layout>
    }
}

export default Faq