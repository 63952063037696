import React from 'react'
import { Link } from 'react-router-dom'
import {
    Card, Row, Col, Typography
} from 'antd'
import Layout from '../../layout'
import background8 from '../../multimedia/background8.svg'
import icon7 from '../../multimedia/icon7.svg'
import icon14 from '../../multimedia/icon14.svg'
import styles from '../../styles'

class PrevalueVehicleDomList extends React.Component {

    render() {
        return <Layout type={"frontpage"}>
            <Card
                cover={<img src={background8} alt={"Fondo"} />}
                bordered={false}
                style={styles.generalCard}
            >
                <Card className={'text-center'} bordered={false}>
                    <Row>
                        <Col span={24}>
                            <Link to={"/prevaluedom/moto"}>
                                <img src={icon7} alt={"MOTO"} style={styles.iconMenu} />
                                <Typography.Title level={5} >MOTO</Typography.Title>
                            </Link>
                            <br />
                        </Col>
                        <Col span={24}>
                            <Link to={"/prevaluedom/car"}>
                                <img src={icon14} alt={"CARRO"} style={styles.iconMenu} />
                                <Typography.Title level={5} >CARRO</Typography.Title>
                            </Link>
                        </Col>
                    </Row>
                </Card>
            </Card>
        </Layout>
    }
}

export default PrevalueVehicleDomList