import React from 'react'
import { connect } from 'react-redux'
import { getEntity } from '../reducers'
import { fetchEntity, fetchEntityWithId } from '../actions'
import {
    Card, Select, Form, Button, Upload, Typography
} from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import background11 from '../multimedia/background11.svg'
import styles from '../styles'

const normFile = (e) => {
    if (Array.isArray(e)) {
        return e
    }
    return e && e.fileList
}

class Electro extends React.Component {

    componentDidMount() {
        this.props.fetchEntity('family')
    }

    handleFamily = (value) => {
        this.props.fetchEntityWithId('product', value)
        this.props.setUpdateState({
            familyName: this.props.families.reduce((ret, obj) => {
                if (obj.nCodFamilia === value) return obj.cDescripcion
                return ret
            }, '')
        })
    }

    handleProduct = (value) => {
        this.props.fetchEntityWithId('brand', value)
        this.props.setUpdateState({
            productName: this.props.products.reduce((ret, obj) => {
                if (obj.nCodArticulo === value) return obj.cDescripcion
                return ret
            }, '')
        })
    }

    handleBrand = (value) => {
        this.props.fetchEntityWithId('line', value)
        this.props.setUpdateState({
            brandName: this.props.brands.reduce((ret, obj) => {
                if (obj.nCodMarca === value) return obj.cDescripcionMarca
                return ret
            }, '')
        })
    }

    handleLine = (value) => {
        this.props.fetchEntityWithId('model', value)
        this.props.setUpdateState({
            lineName: this.props.lines.reduce((ret, obj) => {
                if (obj.nCodLinea === value) return obj.cDescripcionLinea
                return ret
            }, '')
        })
    }

    handleModelo = (value) => {
        this.props.setUpdateState({
            modelName: this.props.models.reduce((ret, obj) => {
                if (obj.nCodModelo === value) return obj.cDescripcionModelo
                return ret
            }, '')
        })
    }

    render() {
        const { families, products, brands, lines, models, setElectro, setUpdateState } = this.props
        return <Card
            style={styles.generalCard}
            bordered={false}
            cover={<img src={background11} alt={"Fondo"} />}
            bodyStyle={styles.cardNoPaddingTop}
        >
            <Typography.Title align={"center"}>Pre-valuós</Typography.Title>
            <Form
                name={"electro"}
                onFinish={setElectro}
                layout={"vertical"}
            >
                <Form.Item label={"Tipo de articulo"} name={"family"} rules={[{ required: true, message: 'Este campo es requerido' }]}>
                    <Select
                        showSearch
                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        onChange={this.handleFamily}
                    >
                        {families.map(family =>
                            <Select.Option value={family.nCodFamilia} key={family.nCodFamilia} >{family.cDescripcion}</Select.Option>
                        )}
                    </Select>
                </Form.Item>
                <Form.Item label={"Tipo de producto"} name={"product"} rules={[{ required: true, message: 'Este campo es requerido' }]}>
                    <Select
                        showSearch
                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        onChange={this.handleProduct}
                    >
                        {products.map(product =>
                            <Select.Option value={product.nCodArticulo} key={product.nCodArticulo} >{product.cDescripcion}</Select.Option>
                        )}
                    </Select>
                </Form.Item>
                <Form.Item label={"Marca"} name={"brand"} rules={[{ required: true, message: 'Este campo es requerido' }]}>
                    <Select
                        showSearch
                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        onChange={this.handleBrand}
                    >
                        {brands.map(brand =>
                            <Select.Option value={brand.nCodMarca} key={brand.nCodMarca} >{brand.cDescripcionMarca}</Select.Option>
                        )}
                    </Select>
                </Form.Item>
                <Form.Item label={"Línea"} name={"line"} rules={[{ required: true, message: 'Este campo es requerido' }]}>
                    <Select
                        showSearch
                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        onChange={this.handleLine}
                    >
                        {lines.map(line =>
                            <Select.Option value={line.nCodLinea} key={line.nCodLinea} >{line.cDescripcionLinea}</Select.Option>
                        )}
                    </Select>
                </Form.Item>
                <Form.Item label={"Modelo"} name={"model"} rules={[{ required: true, message: 'Este campo es requerido' }]}>
                    <Select
                        showSearch
                        onChange={this.handleModelo}
                        filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    >
                        {models.map(model =>
                            <Select.Option value={model.nCodModelo} key={model.nCodModelo} >{model.cDescripcionModelo}</Select.Option>
                        )}
                    </Select>
                </Form.Item>

                <Form.Item name="image1" valuePropName="fileList" getValueFromEvent={normFile} rules={[{ required: true, message: 'Este campo es requerido' }]} >
                    <Upload
                        name="image"
                        listType={"picture"}
                        onRemove={() => { setUpdateState({ image1: [] }) }}
                        beforeUpload={file => {
                            setUpdateState({ image1: [file] })
                            return false
                        }}
                        accept="image/png, image/jpeg"
                        maxCount={1}
                    >
                        <Button icon={<UploadOutlined />}>Subir foto frontal</Button>
                    </Upload>
                </Form.Item>
                <Form.Item name="image2" valuePropName="fileList" getValueFromEvent={normFile} rules={[{ required: true, message: 'Este campo es requerido' }]}>
                    <Upload
                        name="image"
                        listType="picture"
                        onRemove={() => { setUpdateState({ image2: [] }) }}
                        beforeUpload={file => {
                            setUpdateState({ image2: [file] })
                            return false
                        }}
                        accept="image/png, image/jpeg"
                        maxCount={1}
                    >
                        <Button icon={<UploadOutlined />} >Subir foto Lateral</Button>
                    </Upload>
                </Form.Item>
                <Form.Item name="image3" valuePropName="fileList" getValueFromEvent={normFile} rules={[{ required: true, message: 'Este campo es requerido' }]} >
                    <Upload
                        name="image"
                        listType="picture"
                        onRemove={() => { setUpdateState({ image3: [] }) }}
                        beforeUpload={file => {
                            setUpdateState({ image3: [file] })
                            return false
                        }}
                        accept="image/png, image/jpeg"
                        maxCount={1}
                    >
                        <Button icon={<UploadOutlined />}>Subir foto trasera</Button>
                    </Upload>
                </Form.Item>
                <Form.Item name="image4" valuePropName="fileList">
                    <Upload
                        name="image"
                        listType="picture"
                        onRemove={() => { setUpdateState({ image4: [] }) }}
                        beforeUpload={file => {
                            setUpdateState({ image4: [file] })
                            return false
                        }}
                        accept="image/png, image/jpeg"
                        maxCount={1}
                    >
                        <Button icon={<UploadOutlined />}>Subir foto adicional</Button>
                    </Upload>
                </Form.Item>
                <Form.Item wrapperCol={{ span: 12, offset: 6 }}>
                    <Button type={"primary"} htmlType="submit" block>Continuar</Button>
                </Form.Item>
            </Form>
        </Card>
    }
}

const mapStateToProps = (state) => ({
    families: getEntity(state, 'family'),
    products: getEntity(state, 'product'),
    brands: getEntity(state, 'brand'),
    lines: getEntity(state, 'line'),
    models: getEntity(state, 'model')
})

const mapDispatchToProps = { fetchEntity, fetchEntityWithId }

export default connect(mapStateToProps, mapDispatchToProps)(Electro)