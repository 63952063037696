import { message } from 'antd'
import axios from 'axios'
import {
    FETCH_PREVALUES,
    FETCH_PREVALUE,
    APIpath
} from '../actions'

const setPrevalues = (prevalues) => ({
    type: FETCH_PREVALUES,
    prevalues
})

const setPrevalue = (prevalue) => ({
    type: FETCH_PREVALUE,
    prevalue
})

export const saveElectroPrevalue = (values) => (dispatch) => {

    axios.post(`${APIpath}/prevalue/electro`, values)
        .then(function (response) {
            message.success('Pre-valúo envíado')
        })
        .catch(function (err) {
            message.error("Ha ocurrido un error")
            console.log(err)
        })
}

export const saveJewelryPrevalue = (values) => (dispatch) => {
    axios.post(`${APIpath}/prevalue/jewelry`, values)
        .then(function (response) {
            message.success('Pre-valúo envíado')
        })
        .catch(function (err) {
            message.error("Ha ocurrido un error")
            console.log(err)
        })
}

export const saveJewelryPrevalueNo = (values) => (dispatch) => {
    axios.post(`${APIpath}/prevalue/jewelryNo`, values)
        .then(function (response) {
            message.success('Pre-valúo envíado')
        })
        .catch(function (err) {
            message.error("Ha ocurrido un error")
            console.log(err)
        })
}

export const saveVehiclePrevalue = (values) => (dispatch) => {
    axios.post(`${APIpath}/prevalue/vehicle`, values)
        .then(function (response) {
            message.success('Pre-valúo envíado')
        })
        .catch(function (err) {
            message.error("Ha ocurrido un error")
            console.log(err)
        })
}

export const fetchPrevalues = (type) => (dispatch) => {
    axios.get(`${APIpath}/prevalue/${type}`)
        .then(function (response) {
            dispatch(setPrevalues(response.data))
        })
        .catch(function (err) {
            message.error("Ha ocurrido un error")
            console.log(err)
        })
}


export const changeStatus = (pid) => dispatch => {
    axios.post(`${APIpath}/prevalue/status`, { id: pid })
        .then(function (response) {
            message.success('Pre-valúo Actualizado')
            dispatch(setPrevalue(response.data[0]))
        })
        .catch(function (err) {
            message.error("Ha ocurrido un error")
            console.log(err)
        })
}