import React from 'react'
import { connect } from 'react-redux'
import { getUser, getAuth, getConfig, getContracts } from '../../reducers'
import { fetchContracts } from '../../actions'
import { Card, Typography, Table } from 'antd'
import CardHeaderInformation from '../../components/CardHeaderInformation'
import Layout from '../../layout'
import styles from '../../styles'

const { Column } = Table

const tableContracts = (contracts) => {
    return contracts.filter(contract => contract.estado === "VIGENTE")
        .map(contract => {
            return {
                key: contract.contrato,
                numerocontrato: contract.numerocontrato,
                pres: contract.pres,
                sal: contract.sal
            }
        })
}

class AccountCash extends React.Component {

    componentDidMount() {
        this.props.fetchContracts(this.props.auth.cDNI)
    }

    getTotalMoneyAvailable = () => {
        return this.props.contracts.reduce((total, contract) => {
            if(contract.estado === "VIGENTE"){
                let amount = contract.pres - contract.sal
                return total + amount
            }else{
                return total
            }
        }, 0)
    }

    render() {
        const { auth, contracts } = this.props
        return <Layout type={"frontpage"}>
            <Card bordered={false}
                title={<Typography.Title level={2} className={"text-center"} style={styles.whiteText}>MI CUENTA</Typography.Title>}
                style={styles.generalCard}
                headStyle={styles.cover}
                bodyStyle={styles.cardNoPadding}
            >
                <CardHeaderInformation
                    name={auth.cNombres + ' ' + auth.cApePat + ' ' + auth.cApeMat}
                    dni={auth.cDNI}
                />
                <Card bordered={false} className={"text-center"}>
                    <Typography.Title level={2}>EFECTIVO DISPONIBLE</Typography.Title>
                    <Typography.Title level={1} style={styles.yellowCover}  > L{parseFloat(this.getTotalMoneyAvailable()).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} </Typography.Title>
                </Card>
                <Table dataSource={tableContracts(contracts).filter((contract) => {
                    return contract.pres - contract.sal !== 0
                })}>
                    <Column title={"Contrato"} render={(record) => record.numerocontrato} />
                    <Column title={"Disponible"} render={(record) => `L${parseFloat(record.pres - record.sal).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`} />
                </Table>
            </Card>
        </Layout>
    }
}

const mapStateToProps = (state) => ({
    auth: getUser(state, getAuth(state)),
    config: getConfig(state),
    contracts: getContracts(state)
})

const mapDispatchToProps = { fetchContracts }

export default connect(mapStateToProps, mapDispatchToProps)(AccountCash)