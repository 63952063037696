import React from 'react'
import { Button, Card, Radio, Typography } from 'antd'
import { CaretDownOutlined } from '@ant-design/icons'
import icon15 from '../multimedia/icon15.svg'
import icon5 from '../multimedia/icon5.svg'
import styles from '../styles'

class Delivery extends React.Component {

    state = {
        delivery: 0
    }

    handleChange = e => this.setState({ delivery: e.target.value })

    handleDelivery = () => {
        this.props.setDelivery(this.state.delivery)
    }

    render() {
        const { delivery } = this.state
        const { cancelDelivery } = this.props
        return <Card
            style={styles.generalCard}
            bordered={false}
            title={<Typography.Title level={3} style={styles.whiteText} strong>¿CÓMO DESEAS <br /> TU DESEMBOLSO? <CaretDownOutlined /></Typography.Title>}
            headStyle={styles.cover}
        >
            <div style={styles.flexColumnCenter}>
                <Radio.Group onChange={this.handleChange} value={delivery} style={styles.flexColumnCenter}>
                    <Radio value={1}>
                        <img src={icon15} alt={"Tienda en línea"} style={styles.iconMenu} />
                        <Typography.Title level={5} style={styles.greenBtext} >En agencia</Typography.Title >
                    </Radio>
                    <Radio value={2}>
                        <img src={icon5} alt={"Empeños a domicilio"} style={styles.iconMenu} />
                        <Typography.Title level={5} style={styles.greenBtext}>Empeño a domicilio</Typography.Title >
                    </Radio>
                </Radio.Group>
            </div>
            <Button type={"ghost"} onClick={cancelDelivery} >Regresar</Button>
            <Button type={"primary"} onClick={this.handleDelivery}>Continuar</Button>

        </Card>
    }
}


export default Delivery