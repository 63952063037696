import React from 'react'
import { connect } from 'react-redux'
import { getAgencies } from '../reducers'
import {
    Card, Button, Radio, Select, List, Avatar, Affix
} from 'antd'
import styles from '../styles'
import { APIpath } from '../actions'

const filterAgencies = (agencies, zone, filter) => {
    if (filter) {
        return agencies.filter((agency) => {
            return Number(agency.nCodZona) === zone
        })
    }
    return agencies
}

class AgencySelection extends React.Component {

    state = {
        filter: false,
        zone: 0,
        agency: ''
    }

    handleChange = (value) => {
        let filter = value !== 0
        this.setState({ zone: value, filter })
    }

    handleValue = (e) => {
        this.setState({ agency: e.target.value })
    }

    handleAgency = () => {
        this.props.setAgency(this.state.agency)
    }

    render() {
        const { agencies, cancelAgency } = this.props
        const { zone, filter } = this.state
        return <Card
            style={styles.generalCard}
            bordered={false}
        >
            <Affix>
                <div style={styles.backgroundOpacity}>
                    <div style={styles.blockInformationPrimary}>
                        <legend>Selecciona tu zona:</legend>
                        <Select style={{ width: '100%' }} onChange={this.handleChange} value={zone}>
                            <Select.Option value={0}>Todas las agencias</Select.Option>
                            <Select.Option value={1}>Zona centro</Select.Option>
                            <Select.Option value={2}>Zona norte</Select.Option>
                            <Select.Option value={3}>Zona litoral</Select.Option>
                            <Select.Option value={4}>Zona interior</Select.Option>
                        </Select>
                    </div>
                    <br />
                    <Button type={"ghost"} onClick={cancelAgency} >Regresar</Button>
                    <Button type={"primary"} onClick={this.handleAgency}>Continuar</Button>
                </div>
            </Affix>

            <Radio.Group onChange={this.handleValue}>
                <List>
                    {filterAgencies(agencies, zone, filter).map(agency => {
                        return <List.Item
                            key={agency.id}
                            actions={[<Avatar size={64} src={`${APIpath}/agency/image/${agency.id}`} />]}
                        >
                            <Radio value={agency.name} >{agency.name}</Radio>
                        </List.Item>
                    })}
                </List>
            </Radio.Group>
        </Card>
    }
}

const mapStateToProps = (state) => ({
    agencies: getAgencies(state)
})

const mapDispatchToProps = undefined

export default connect(mapStateToProps, mapDispatchToProps)(AgencySelection)