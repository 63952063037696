import React from 'react'
import { Link } from 'react-router-dom'
import {
    Card, Row, Col, Typography
} from 'antd'
import Layout from '../../layout'
import background10 from '../../multimedia/background10.svg'
import icon13 from '../../multimedia/icon13.svg'
import icon11 from '../../multimedia/icon11.svg'
import icon6 from '../../multimedia/icon6.svg'
import styles from '../../styles'

class PrevalueList extends React.Component {

    render() {
        return <Layout type={"frontpage"}>
            <Card
                cover={<img src={background10} alt={"Fondo"} />}
                bordered={false}
                className={'text-center'}
                style={styles.generalCard}
            >
                <Row>
                    <Col span={24}>
                        <Link to={"/prevaluedom/electro"}>
                            <img src={icon13} alt={"ARTICULOS y MÁS"} style={styles.iconMenu} />
                            <Typography.Title level={5} >ARTICULOS y MÁS</Typography.Title>
                        </Link>
                    </Col>
                    <Col span={24}>
                        <Link to='/prevaluedom/jewel'>
                            <img src={icon11} alt={"JOYAS DE ORO"} style={styles.iconMenu} />
                            <Typography.Title level={5} >JOYAS DE ORO</Typography.Title>
                        </Link>
                    </Col>
                    <Col span={24}>
                        <Link to='/prevaluedom/vehicle'>
                            <img src={icon6} alt={"VEHICULOS"} style={styles.iconMenu} />
                            <Typography.Title level={5} >VEHICULOS / MOTOS</Typography.Title>
                        </Link>
                    </Col>
                </Row>
            </Card>
        </Layout>
    }
}

export default PrevalueList