import React from 'react'
import { connect } from 'react-redux'
import { saveVehiclePrevalue } from '../../actions'
import Layout from '../../layout'
import Moto from '../../containers/Moto'
import NoLoginForm from '../../containers/NoLoginForm'
import Delivery from '../../containers/Delivery'
import AddressForm from '../../containers/AddressForm'
import AgencySelection from '../../containers/AgencySelection'
import ConfirmationBlock from '../../components/ConfirmationBlock'

class NoLPrevalueMoto extends React.Component {

    state = {
        page: 1,
        type: 5,
        tarjeta: 0,
        marca: '',
        anno: 0,
        modelo: 0,
        kilometraje: 0,
        nContacto: 0,
        image1: [],
        image2: [],
        image3: [],
        image4: [],
        image5: [],
        delivery: 0,
        agency: 0,
        address: '',
        addressRef: '',
        departamento: '',
        municipio: '',
        phone: '',
        email: '',
        name: '',
        contactFrom: 2
    }

    setMoto = (entities) => {
        this.setState(entities, () => { this.setState({ page: 2 }) })
    }

    setUpdateState = (object) => {
        this.setState(object)
    }

    setInitialForm = (values) => {
        this.setState(values, () => { this.setState({ page: 0 }) }) 
    }

    setDelivery = (value) => {
        this.setState({ delivery: value }, () => {
            if (value === 1) {
                this.setState({ page: 3 })
            } else {
                this.setState({ page: 4 })
            }
        })
    }

    setAddress = (values) => {
        this.setState(values, () => {
            this.saveForm()
        })
    }

    setAgency = (value) => {
        this.setState({ agency: value }, () => {
            this.saveForm()
        })
    }

    saveForm = () => {
        const { saveVehiclePrevalue } = this.props

        const formData = new FormData()
        this.state.image1.forEach(file => {
            formData.append('image1', file.originFileObj)
        })
        this.state.image2.forEach(file => {
            formData.append('image2', file.originFileObj)
        })
        this.state.image3.forEach(file => {
            formData.append('image3', file.originFileObj)
        })
        this.state.image4.forEach(file => {
            formData.append('image4', file.originFileObj)
        })
        this.state.image5.forEach(file => {
            formData.append('image5', file.originFileObj)
        })
        formData.append('type', this.state.type)
        formData.append('marca', this.state.marca)
        formData.append('tarjeta', this.state.tarjeta)
        formData.append('anno', this.state.anno)
        formData.append('modelo', this.state.modelo)
        formData.append('kilometraje', this.state.kilometraje)
        formData.append('nContacto', this.state.nContacto)
        formData.append('delivery', this.state.delivery)
        formData.append('agency', this.state.agency)
        formData.append('address', this.state.address)
        formData.append('addressRef', this.state.addressRef)
        formData.append('departamento', this.state.departamento)
        formData.append('municipio', this.state.municipio)
        formData.append('contactFrom', this.state.contactFrom)
        formData.append('nCodPers', 0)
        formData.append('name', this.state.name)
        formData.append('phone', this.state.phone)
        formData.append('email', this.state.email)
        saveVehiclePrevalue(formData)
        this.setState({ page: 5 })
    }

    cancelInitialForm = () => this.setState({ page: 2, address: '', phone: '', addressRef: '', departamento: '', municipio: '' })
    cancelAgency = () => this.setState({ page: 2, agency: 0 })
    cancelDelivery = () => this.setState({ page: 0, delivery: 0 })
    cancelAddress = () => this.setState({ page: 2, address: '', phone: '', addressRef: '', departamento: '', municipio: '' })


    render() {
        const { page } = this.state
        switch (page) {
            case 1:
                return <Layout type={"NoLogin"} >
                    <NoLoginForm cancelInitialForm={this.cancelInitialForm} setInitialForm={this.setInitialForm} />
                </Layout>
            case 2:
                return <Layout type={"frontpage"} >
                    <Delivery setDelivery={this.setDelivery} cancelDelivery={this.cancelDelivery} />
                </Layout>
            case 3:
                return <Layout type={"frontpage"} >
                    <AgencySelection cancelAgency={this.cancelAgency} setAgency={this.setAgency} />
                </Layout>
            case 4:
                return <Layout type={"frontpage"} >
                    <AddressForm cancelAddress={this.cancelAddress} setAddress={this.setAddress}  defaultValues={this.state} />
                </Layout>
            case 5:
                return <Layout type={"NoLogin"} >
                    <ConfirmationBlock message={`Estimado cliente: El monto podrá variar de acuerdo al avalúo físico de su artículo.
¡No lo pienses más y ven a CrediMás donde tus prendas las VALORAMOS más!`}
                    />
                </Layout>
            default:
                return <Layout type={"NoLogin"} >
                    <Moto setMoto={this.setMoto} setUpdateState={this.setUpdateState} />
                </Layout>
        }
    }
}


const mapDispatchToProps = { saveVehiclePrevalue }

export default connect(undefined, mapDispatchToProps)(NoLPrevalueMoto)