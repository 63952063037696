import React from 'react'
import { connect } from 'react-redux'
import { updateUser } from '../actions'
import { Button, Descriptions, Input } from 'antd'
import { EditOutlined, SaveOutlined, CloseOutlined, WhatsAppOutlined } from '@ant-design/icons'
import styles from '../styles'


class UserInformation extends React.Component {

    state = {
        edit: false,
        email: '',
        phone: ''
    }

    editMode = () => {
        const { user } = this.props
        const { edit } = this.state
        if (edit) {
            this.setState({ edit: false, email: '', phone: '' })
        } else {
            this.setState({ edit: true, email: user.cMail, phone: user.cMovil })
        }
    }

    handleEmail = (e) => this.setState({ email: e.currentTarget.value })

    handlePhone = (e) => this.setState({ phone: e.currentTarget.value })

    saveUser = () => {
        const { updateUser, user } = this.props
        updateUser({
            nCodPers: user.nCodPers,
            cMovil: this.state.phone,
            cMail: this.state.email
        })
        this.setState({ edit: false, email: '', phone: '' })
    }

    render() {
        const { user } = this.props
        const { edit, email, phone } = this.state
        return (
            <React.Fragment>
                <Descriptions
                    bordered
                    column={1}
                    size={"small"}
                    title={"Información del cliente"}
                    extra={edit ?
                        <div>
                            <Button onClick={this.editMode} ><CloseOutlined /></Button>
                            <Button type={"primary"} onClick={this.saveUser} ><SaveOutlined /></Button>
                        </div>
                        : <Button onClick={this.editMode} ><EditOutlined /></Button>
                    }
                    style={styles.generalCard}
                >
                    <Descriptions.Item label={"ID"}> {user.cDNI}</Descriptions.Item>
                    <Descriptions.Item label={"Nombre"}> {user.cNombres} {user.cApePat} {user.cApeMat} </Descriptions.Item>
                    <Descriptions.Item label={"Correo"}>
                        {edit ?
                            <Input name="email" placeholder={"Correo"} type={"email"} value={email} onChange={this.handleEmail} />
                            : user.cMail
                        }
                    </Descriptions.Item>
                    <Descriptions.Item label={"Movil"}>
                        {edit ?
                            <Input name="phone" placeholder={"Teléfono"} value={phone} onChange={this.handlePhone} />
                            : user.cMovil
                        }
                    </Descriptions.Item>
                </Descriptions>
                <Button
                    type={"primary"}
                    href={`https://wa.me/+504${user.cMovil.replace('-', '')}?text=Estimado%20cliente%20le%20saludamos%20de%20CREDIMAS,%20ingresa%20ya%20a%20nuestra%20App%20Web:%20https://credimas.hn/%20busca%20el%20botón%20Mi%20Cuenta%20Credimas%20y%20podrás%20ver:%201.%20Saldos%20y%20fechas%20de%20vencimiento%20de%20contratos%20activos,%202.%20Realizar%20prevalúos%20al%20instante,%203.%20Solicitar%20empeños%20a%20domicilios%204.%20y%20muchas%20cosas%20más.%20CONTRASEÑA%20Mi%20Cuenta%20Credimas:`}
                    icon={<WhatsAppOutlined />}
                    target={"_blank"}
                    rel="noreferrer"
                > Enviar clave generada
                </Button>
            </React.Fragment>
        )
    }
}

const mapStateToProps = undefined

const mapDispatchToProps = { updateUser }

export default connect(mapStateToProps, mapDispatchToProps)(UserInformation)