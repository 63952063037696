import React from 'react'
import { connect } from 'react-redux'
import { withRouter, Redirect } from "react-router-dom"
import { login } from '../../actions'
import { getAuth } from '../../reducers'
import { Form, Field } from 'react-final-form'
import { Button, Card, Typography } from 'antd'
import { AntdInput as Input } from 'antd-final-form'
import { required } from '../../utils/validationForms'
import { ReactComponent as Logo } from '../../multimedia/logo.svg'
import Layout from '../../layout'
import styles from '../../styles'

class Login extends React.Component {

    render() {
        const { location, login, isAuth } = this.props
        if (isAuth) {
            let { from } = location.state || { from: { pathname: '/' } }
            return <Redirect to={from} />
        }

        return <Layout>
            <Card style={styles.cardAuthpage}>
                <Form
                    onSubmit={login}
                    render={({ handleSubmit, submitting, pristine }) => (
                        <form onSubmit={handleSubmit}>
                            <div style={styles.textCenter}>
                                <Logo style={styles.logo} />
                            </div>
                            <Typography.Title level={2} style={styles.textCenter} >¡Bienvenido!</Typography.Title>
                            <Typography.Title level={4} >Iniciar sesión</Typography.Title>
                            <Field label="ID" name="dni" component={Input} placeholder={"Ingrese su ID"} validate={required} />
                            <Field label="Contraseña" name="password" component={Input} placeholder={"Ingrese su Contraseña"} type={"password"} validate={required} />
                            <br />
                            <Button type="primary" htmlType={'submit'} disabled={submitting || pristine} block>Ingresar</Button>
                        </form>
                    )}
                >
                </Form>
            </Card>
        </Layout>
    }
}

const mapStateToProps = (state) => ({
    isAuth: getAuth(state) === undefined ? false : true
})

const mapDispatchToProps = ({ login })

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login))