import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { getAgencies } from '../../reducers'
import styles from '../../styles'
import {
    Card, Descriptions, Skeleton, Avatar, Select
} from 'antd'
import { WhatsAppOutlined, GlobalOutlined } from '@ant-design/icons'
import Layout from '../../layout'
import { APIpath } from '../../actions'

const filterAgencies = (agencies, zone, filter) => {
    if (filter) {
        return agencies.filter((agency) => {
            return Number(agency.nCodZona) === zone
        })
    }
    return agencies
}

class AgenciesList extends React.Component {

    state = {
        filter: false,
        zone: 0
    }

    handleChange = (value) => {
        let filter = value !== 0
        this.setState({ zone: value, filter })
    }


    render() {
        const { agencies } = this.props
        const { zone, filter } = this.state
        return <Layout type={"frontpage"}>
            <div style={styles.blockInformationPrimary}>
                <legend>Selecciona tu zona:</legend>
                <Select style={{ width: '100%' }} onChange={this.handleChange} value={zone}>
                    <Select.Option value={0}>Todas las agencias</Select.Option>
                    <Select.Option value={1}>Zona centro</Select.Option>
                    <Select.Option value={2}>Zona norte</Select.Option>
                    <Select.Option value={3}>Zona litoral</Select.Option>
                    <Select.Option value={4}>Zona interior</Select.Option>
                </Select>
            </div>
            <br />
            {agencies.length > 0 ?
                filterAgencies(agencies, zone, filter).map(agency => {
                    return <Card
                        key={agency.id}
                        style={styles.generalCard}
                        actions={[
                            <Link to={`/agency/list/${agency.id}`}>
                                Más Información
                            </Link>,
                            <a href={`https://wa.me/+504${agency.cNroWhatsApp}`} target={"_blank"} rel="noreferrer">
                                <WhatsAppOutlined /> Escríbenos
                            </a>,
                            <a href={agency.cURLMaps} target={"_blank"} rel="noreferrer">
                                <GlobalOutlined /> Ver mapa
                            </a>
                        ]}
                    >
                        <Card.Meta
                            avatar={<Avatar size={64} src={`${APIpath}/agency/image/${agency.id}`} />}
                            title={<h2> {agency.name} </h2>}
                        />
                        <Descriptions column={1}>
                            <Descriptions.Item label="Teléfono">{agency.phone}</Descriptions.Item>
                            <Descriptions.Item label="Dirección">{agency.address}</Descriptions.Item>
                        </Descriptions>
                    </Card>
                })
                : <Skeleton />
            }
        </Layout>
    }
}

const mapStateToProps = (state) => ({
    agencies: getAgencies(state)
})

const mapDispatchToProps = undefined

export default connect(mapStateToProps, mapDispatchToProps)(AgenciesList)